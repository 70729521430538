//import _ from 'lodash';

/* function component() {
    const element = document.createElement('div');
  
    element.innerHTML = _.join(['Hello', 'webpack'], ' ');
  
    return element;
  } */
  
  //document.body.appendChild(component());

  // Import the functions you need from the SDKs you need
//import { initializeApp } from "https://www.gstatic.com/firebasejs/9.1.2/firebase-app.js";
//import { getAnalytics } from "https://www.gstatic.com/firebasejs/9.1.2/firebase-analytics.js";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

import 'bootstrap';
import './sass/style.scss';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDQCUIFYFCOFZT6xjE0qPk-J1liVXx6hNQ",
  authDomain: "newmetal-app.firebaseapp.com",
  projectId: "newmetal-app",
  storageBucket: "newmetal-app.appspot.com",
  messagingSenderId: "853985503878",
  appId: "1:853985503878:web:c0a6938bb582acdc1abf07",
  measurementId: "G-8SWGBRX18C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);